<template>
	<!-- 전체 앱 래퍼 -->
	<div class="app-contents">

		<navbar-comp />

		<router-view />

		<img-work-detl-modal v-if="model.grupId" :grupId="model.grupId"
                         :itemId="model.itemId" :rgstStep="model.rgstStep" />

		<crtr-info-modal v-if='user.getCreator' :crtrId="user.getCreator" />
    <my-info-modal v-if="user.getShowMyInfo" />

    <spnr-full-block v-show="mdal.isLoading" />
    <spnr-no-block v-show="mdal.isNonBlckLodn" />

		<tost-noti />
		<cnfm-modal v-if='mdal.showCnfm' />
		<alrt-modal v-if='mdal.showAlrt' />

	</div>
	<!-- 전체 앱 래퍼 -->
</template>

<script setup>
import NavbarComp from '@/view/sect/NavbarComp.vue'
import CnfmModal from '@/view/pgrsAlrt/CnfmModal.vue'
import SpnrFullBlock from '@/view/pgrsAlrt/SpnrFullBlock.vue'
import TostNoti from '@/view/pgrsAlrt/TostNoti.vue'
import AlrtModal from '@/view/pgrsAlrt/AlrtModal.vue'
import CrtrInfoModal from "@/view/modal/CrtrInfoModal.vue"
import ImgWorkDetlModal from '@/view/modal/ImgWorkDetlModal.vue'
import { RouterView } from 'vue-router'
import { user_stor } from '@/stor/user_stor'
import { onBeforeUnmount, onMounted, reactive, watch } from 'vue'
import MyInfoModal from "@/view/modal/MyInfoModal.vue"
import { mdal_stor } from '@/stor/mdal_stor'
import { chckFrstAccs, initOnce } from '@/util/auth_func'
import { getCartCnt, getNotiList } from '@/util/comn_func'
import SpnrNoBlock from '@/view/pgrsAlrt/SpnrNoBlock.vue'

const mdal = mdal_stor()
const user = user_stor()
const model = reactive({
  init: false,
	grupId: null,
	itemId: null,
  rgstStep: null,
	imgModal: null,
	destroy: null,
  itvlId: -1
})

watch(() => user.getPmptImgGrupId
	, (newParam) => {
		if (newParam!==null) {
			model.grupId = newParam[0]
			model.itemId = newParam[1]
      model.rgstStep = newParam[2]
		} else {
			console.log('[APP] close media detail')
			model.grupId = null
			model.itemId = null
      model.rgstStep = null
		}
})

onMounted(() => {

  if (model.init===false) {
    initOnce()
    model.init = true
  }

  if (user.isSignedIn) {
    chckFrstAccs()
    if (model.itvlId === -1) {
      model.itvlId = setInterval(
        user.chckToknExprThenSignOut
        , (60 * 1000))
    }
    getCartCnt()
    getNotiList()
  }
})

onBeforeUnmount(() => {
  if (model.itvlId && model.itvlId>-1) {
    clearInterval(model.itvlId)
  }
})

</script>
