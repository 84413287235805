import '@/assets/scss/app.scss'
import '@vuepic/vue-datepicker/dist/main.css'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import '@/assets/css/comn.css'
import '@/assets/css/flag.css'
import '@/assets/css/tabl.css'
import '@/assets/css/butn.css'
import '@/assets/css/mdal.css'
import VWave from 'v-wave'
import Vue3WordCloud from 'vue3-word-cloud'
import VueZoomer from 'vue-zoomer'
import App from './App.vue'
import router from './rout'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Popper from 'vue3-popper'
import VueDatePicker from '@vuepic/vue-datepicker'
import VMdEditor from '@kangc/v-md-editor'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'
import Prism from 'prismjs'
import 'prismjs/components/prism-json' // highlight code
import VueSplide from '@splidejs/vue-splide'
import LoadScript from 'vue-plugin-load-script'
import { i18n } from '@/lcal/i18n'
import ScriptX from 'vue-scriptx'
import Ads from 'vue-google-adsense'

VMdEditor.use(vuepressTheme, { Prism })
VMdEditor.lang.use('en-US', enUS)

if (!(import.meta.env.VITE_APP_HOST === "http://localhost"
  || import.meta.env.VITE_APP_HOST === "https://dev.gen8.id")) {
  console.log = function () {}
}

const app = createApp(App)
app.use(ScriptX)
  .use(Ads.Adsense)
  .use(Ads.InArticleAdsense)
  .use(Ads.InFeedAdsense)
	.use(createPinia())
	.use(VWave, { color: 'green' })
	.use(VueSplide)
	.use(VueZoomer)
  .use(LoadScript)
	.use(router)
	.use(VMdEditor)
  .use(i18n)
	.component('vue3-word-cloud', Vue3WordCloud)
	.component('Popper', Popper)
	.component('VueDatePicker', VueDatePicker)
	.mount('#app')
