<template>
	<!--begin::Modal - 내정보-->
	<div class="modal" tabindex="-1">
		<!--begin::Modal dialog-->
		<div class="modal-dialog modal-sm">
			<!--begin::Modal content-->
			<div class="modal-content">
				<!--begin::Modal body-->
				<div class="modal-body" v-if="model.citeInfo">

					<div class="modal-header">
						<h3 class="mx-2 my-1"><i class="fa-regular fa-message-quote me-2"></i> Quot Image Source</h3>
					</div>

          <div class="modal-body">

            <!-- quotation start -->
            <div style="height: fit-content;">
              <!-- image area start -->
              <div style="position: relative; overflow: hidden; width: 100%; height: fit-content; min-height: 250px; max-height: 363px; text-align: center;">
                <img :src="model.citeInfo.imgUrl" style="position: absolute; left: 50%; top: 50%; max-width: 100%; max-height: 100%; width: 100%; height: 100%; object-fit: contain; transform: translate(-50%, -50%); display: block;" />
              </div>
              <!-- image area end -->
              <!-- text area start -->
              <div style="text-align: center;margin-top: 0;">
                <div v-if="model.quotLen==='s'" style="font-size: 11px;">Image from <a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a></div>
                <div v-if="model.quotLen==='m'" style="font-size: 11px;">Image by {{ model.citeInfo.crtrNm }} from <a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a></div>
                <div v-if="model.quotLen==='l'" style="font-size: 11px; display: flex; justify-content: center;">
                  {{ model.citeInfo.crtrNm }},&nbsp;<div style="max-width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; word-break: break-all;">"{{ model.citeInfo.titl }}"</div>,&nbsp;<a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a>
                </div>
              </div>
              <!-- text area end -->
            </div>
            <!-- quotation end -->

            <div class="px-3 my-3">
              <textarea class="tag-area w-100 text-primary p-2 fs-9 fw-lighter" v-model="model.htmlTag"></textarea>
            </div>

            <div class="w-100 d-flex px-3">

              <div v-if="model.quotLen" class="btn-group">

                <input
                  id="s"
                  v-model="model.quotLen"
                  value="s"
                  autocomplete="off"
                  class="btn-check"
                  type="radio"
                  :checked="model.quotLen === 's'"
                />
                <label for="s" class="btn btn-outline-primary px-2 text-center" @click="toSmamQuot()">
                  Short
                </label>

                <input
                  id="m"
                  v-model="model.quotLen"
                  value="m"
                  autocomplete="off"
                  class="btn-check"
                  type="radio"
                  :checked="model.quotLen === 'm'"
                />
                <label for="m" class="btn btn-outline-primary px-2 text-center" @click="toMedmQuot($event)">
                  Normal
                </label>

                <input
                  id="l"
                  v-model="model.quotLen"
                  value="l"
                  autocomplete="off"
                  class="btn-check"
                  type="radio"
                  :checked="model.quotLen === 'l'"
                />
                <label for="l" class="btn btn-outline-primary px-2 text-center" @click="toFullQuot($event)">
                  Full
                </label>

              </div>

              <div class="flex-grow-1 text-end">
                <div class="btn btn-sm btn-primary d-inline-flex" @click="onCopyClck($event)">
                  <i class="fa-solid fa-scissors me-2"></i><div>Copy</div>
                </div>
              </div>

            </div>

            <div class="mx-3">
              <p class="text-dark fs-8 fw-light indt-titl mt-3">
                ※ The style of text and image can be adjusted, except the original website name and the URL link.
              </p>
            </div>

          </div>

				</div>
				<!--end::Modal body-->

				<btn-close-top-right @onCloseClick="emit('closeModal')" />

			</div>
			<!--end::Modal content-->
		</div>
		<!--end::Modal dialog-->
	</div>
	<!--end::Modal - 내정보-->

</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { user_stor } from '@/stor/user_stor'
import { cnst_stor } from '@/stor/cnst_stor'
import BtnCloseTopRight from '@/view/btns/BtnCloseTopRight.vue'
import { mdal_stor } from '@/stor/mdal_stor'

const mdal = mdal_stor()
const emit  = defineEmits(['closeModal'])
const cnst = cnst_stor()
const user = user_stor()
const prop = defineProps(['citeInfo'])
const sHtml =
  '<!-- quotation start -->\n' +
  '<div style="height: fit-content;">\n' +
  '  <!-- image area start -->\n' +
  '  <div style="position: relative; overflow: hidden; width: 100%; height: fit-content; min-height: 250px; max-height: 363px; text-align: center;">\n' +
  '    <img :src="model.citeInfo.imgUrl" style="position: absolute; left: 50%; top: 50%; max-width: 100%; max-height: 100%; width: 100%; height: 100%; object-fit: contain; transform: translate(-50%, -50%); display: block;" />\n' +
  '  </div>\n' +
  '  <!-- image area end -->\n' +
  '  <!-- text area start -->\n' +
  '  <div style="text-align: center;margin-top: 0;">\n' +
  '    <div style="font-size: 11px;">Image from <a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a></div>\n' +
  '  </div>\n' +
  '  <!-- text area end -->\n' +
  '</div>\n' +
  '<!-- quotation end -->'
let mHtml
let lHtml
const model = reactive({
  citeInfo: null,
  quotLen: 'm',
  htmlTag: ''
})

onMounted(() => {
  model.citeInfo = prop.citeInfo
  mHtml =
    '<!-- quotation start -->\n' +
    '<div style="height: fit-content;">\n' +
    '  <!-- image area start -->\n' +
    '  <div style="position: relative; overflow: hidden; width: 100%; height: fit-content; min-height: 250px; max-height: 363px; text-align: center;">\n' +
    '    <img :src="model.citeInfo.imgUrl" style="position: absolute; left: 50%; top: 50%; max-width: 100%; max-height: 100%; width: 100%; height: 100%; object-fit: contain; transform: translate(-50%, -50%); display: block;" />\n' +
    '  </div>\n' +
    '  <!-- image area end -->\n' +
    '  <!-- text area start -->\n' +
    '  <div style="text-align: center;margin-top: 0;">\n' +
    `    <div style="font-size: 11px;">Image by ${model.citeInfo?model.citeInfo.crtrNm:''} from <a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a></div>\n` +
    '  </div>\n' +
    '  <!-- text area end -->\n' +
    '</div>\n' +
    '<!-- quotation end -->'
  lHtml =
    '<!-- quotation start -->\n' +
    '<div style="height: fit-content;">\n' +
    '  <!-- image area start -->\n' +
    '  <div style="position: relative; overflow: hidden; width: 100%; height: fit-content; min-height: 250px; max-height: 363px; text-align: center;">\n' +
    '    <img :src="model.citeInfo.imgUrl" style="position: absolute; left: 50%; top: 50%; max-width: 100%; max-height: 100%; width: 100%; height: 100%; object-fit: contain; transform: translate(-50%, -50%); display: block;" />\n' +
    '  </div>\n' +
    '  <!-- image area end -->\n' +
    '  <!-- text area start -->\n' +
    '  <div style="text-align: center;margin-top: 0;">\n' +
    '    <div style="font-size: 11px; display: flex; justify-content: center;">\n' +
    `      ${model.citeInfo.crtrNm},&nbsp;<div style="max-width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; word-break: break-all;">"${model.citeInfo.titl}"</div>,&nbsp;<a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a>\n` +
    '    </div>\n' +
    '  <!-- text area end -->\n' +
    '</div>\n' +
    '<!-- quotation end -->'
  model.htmlTag = mHtml
})

function onCopyClck() {
  window.navigator.clipboard
    .writeText(model.htmlTag)
    .then(() => {
      mdal.toast(
        '<i class="fa-regular fa-copy me-2"></i>The HTML TEXT to embed has<br/>been copied to the clipboard.'
      )
    })
}

/**
 * /ress/pmpt/imgs/241025/16RNe29tp1H/scewdvzsw7zafnfsl8kst.avif
 * /ress/pmpt/imgs/241025/16RNe29tp1H/scewdvzsw7zafnfsl8ksm.webp
 */
function toMedmQuot(e) {
  model.quotLen = 'm'
  model.htmlTag = mHtml
}

function toFullQuot(e) {
  model.quotLen = 'l'
  model.htmlTag = lHtml
}

function toSmamQuot(e) {
  model.quotLen = 's'
  model.htmlTag = sHtml
}
</script>

<style scoped>
.modal-dialog {
  overflow-x: hidden;
}
.modal-content { height: fit-content; }
.tag-area {
  border: 1px solid #595959;
  border-radius: 9px;
  height: 130px;
}

.btn-check:checked + .btn {
  background-color: rgb(0, 169, 204) !important;
  color: white !important;
}

.img-wrap img {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 383px;
  height: 100%;
  max-height: 383px;
}

.btn-group .btn {
  height: 28px;
  line-height: 0.9;
}

@media (max-width: 767.98px) {
  .modal-dialog {
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 600px;
    height: 50%;
  }
}
</style>
