<template>
  <!--begin::Modal - 내정보-->
  <div class="modal" tabindex="-1">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-lg round">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal body-->
        <div class="modal-body">
          <div class="row gx-0 crtr-row">

            <!-- left col -->
            <div class="col col-12 col-lg-4 left-col">
              <div
                class="photo-frame text-center align-middle box-400 bg-color-dark-navy"
                :style="model.user?'background-image: url(\'' + model.user.phtoUrl + '\')':''"
              >
                <img class="prof-img-deco svg-shadow-sm" src="@/assets/img/brnd/ic-logo-right.png" />
              </div>

              <table class="table bg-f3f3f3 shadow-smd1">
                <colgroup>
                  <col style="width: 150px;" />
                  <col style="width: auto;" />
                </colgroup>
                <tbody>
                  <tr class="bb-solid m-bt-2 shadow-smd1">
                    <th colspan="2" class="border-end-0 th-crtr-name">
                      <crtr-info-full-horz v-if="model.user" :data="model.user" noClck="true" class="fw-bold justify-content-end" />
                    </th>
                  </tr>
                  <tr class="bb-solid">
                    <td colspan="2" class="text-start border-end-0 td-self-itro inner-shadow-sm self-itro-wrap">
                      <div v-if="model.user" class="user-itro text-dark text-start mb-2"
                           v-html="model.user.selfItro?model.user.selfItro.replace('\n', '<br/>'):'-'">
                      </div>
                      <div v-if="model.user && model.user.crtrSpctList" class="">
                        <span v-for="(item, idx) in model.user.crtrSpctList" :key="idx"
                              class="badge text-bg-secondary h-slim mb-0">
                          {{ item }}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr class="bb-solid">
                    <th class="th-repu">
                      <div class="d-flex">
                        <div class="flex-grow-1 d-flex">
                          <span>Reputation</span>
                        </div>
                        <div class="badge-wrap">
                          <div class="repu-cnt badge btn-outline-primary mx-auto my-0 bg-white">0</div>
                        </div>
                      </div>
                    </th>
                    <td class="py-0 star-wrap">
                      <i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i
                      ><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i
                      ><i class="fa-regular fa-star"></i>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!--Since area -->
              <div class="btn-area px-3 pt-0 pb-3 d-flex">
                <div v-if="model.user" class="flex-grow-1 pt-2 text-start fs-7 text-end fw-medium">
                  Since {{ isoDateToShortYear4(model.user.rgstDttm) }}
                </div>
              </div>
              <!--Since area -->

            </div>
            <!-- left col -->

            <!-- right col -->
            <div class="col col-12 col-lg-8 right-col m-bt-1">
              <h5 class="d-flex h5-titl">
                <div class="flex-grow-1 d-flex">
                  <i class="fa-duotone fa-grid-2"></i>
                  <div>Collections</div>
                  <span v-if="model.totlCnt && model.totlCnt > 0" class="badge normal primary">{{ model.totlCnt }}</span>
                </div>
              </h5>
              <phto-grid-view :list="model.list" class="photo-grid-view" @artClicked="artClicked"
                  theme="white" />
            </div>
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <btn-close-top-right @onCloseClick="closeModal" />
      <!--end::Modal content-->

      <!-- bottom fixed button area -->
      <div class="btn-pos-fix-btm px-3 py-2 w-100 crtr-actn-btns bg-white-leather-rect-big">
        <div class="d-flex justify-content-around">
          <a @click="fllwCrtr"
             class="btn btn-lg btn-outline bg-white shadow-md w-40 ellipsis disabled">
            <div class="icon">
              <i class="fa-solid fa-star-sharp-half-stroke"></i>
            </div>
            <div class="text">Rate</div>
            <div></div>
          </a>
          <a @click="fllwCrtr"
             class="btn btn-lg btn-primary btn-outline shadow-md flex-grow-1 w-60">
            <div class="icon">
              <i class="fa-regular fa-face-smile-plus me-2"></i>
            </div>
            <div class="text">Follow</div>
          </a>
        </div>
      </div>
      <!-- bottom fixed button area -->

    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - 내정보-->
</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive } from 'vue'
import { user_stor } from "@/stor/user_stor"
import BtnCloseTopRight from "@/view/btns/BtnCloseTopRight.vue"
import axis_cstm from "@/util/axis_cstm"
import { API_ROOT, ResCd } from "@/util/comn_cnst"
import emtyUrl from "@/assets/img/icon/ic-no-prof-img-big.webp"
import { isoDateToShortYear4 } from '@/util/comn_util'
import PhtoGridView from "@/view/cpnt/PhtoGridView.vue"
import { mdal_stor } from '@/stor/mdal_stor'
import CrtrInfoFullHorz from '@/view/cpnt/CrtrInfoFullHorz.vue'
import base62 from '@sindresorhus/base62'
import { preventBgScroll, releaseBgScroll } from '@/util/evnt_func'

const mdal = mdal_stor()
const prop = defineProps(["crtrId"])
const emit = defineEmits(["closeModal"])
const user = user_stor()

const model = reactive({
  user: null,
  list: [],
  totlCnt: 0
})

onMounted(() => {
  getUserData()
  getUserArts()
  if (document.body.offsetWidth < 992) {
    preventBgScroll()
  }
})

onBeforeUnmount(() => {
  if (document.body.offsetWidth < 992) {
    releaseBgScroll()
  }
})

function getUserArts() {
  axis_cstm()
    .get(API_ROOT + `pblc/crtr/${prop.crtrId}`)
    .then((res) => {
      const data = res.data
      if (data.code === ResCd.SUCCESS) {
        // console.log('getUserArts', JSON.stringify(data.rslt))
        model.list = data.rslt.list
        model.totlCnt = data.rslt.cnt
      }
    })
    .catch((err) => {
      console.log(`${err.name} ${err.code} ${err.message}`)
    })
    .finally(() => {})
}

function artClicked(grupId, itemId) {
  console.log("artClicked", grupId, itemId)
  user.closImgDetlPopUp()
  location.href = `/view/pmpt/imgs/${grupId}`
  closeModal()
}

function getUserData() {

  const userId = prop.crtrId
  let encdCrtrId = base62.encodeString(userId)
  if (userId) {
    axis_cstm()
      .get(API_ROOT + `pblc/user/${encdCrtrId}`)
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          // console.log("rslt", JSON.stringify(data.rslt))
          model.user = data.rslt
          if (!model.user.phtoUrl) {
            model.user.phtoUrl = emtyUrl
          }
          model.leglNm += model.user.frstName ? model.user.frstName : ""
          model.leglNm += model.user.midlName ? " " + model.user.midlName : ""
          model.leglNm += model.user.lastName ? " " + model.user.lastName : ""
          if (model.leglNm.length < 1) {
            model.leglNm = "-"
          }
        }
      })
      .catch((err) => {
        console.log(`${err.name} ${err.code} ${err.message}`)
      })
      .finally(() => {})
  }
}

function fllwCrtr() {
  mdal.toast('<i class="fa-regular fa-person-digging me-2"></i>This functionality<br/>will be provided soon!')
}

function closeModal() {
  user.showCrtr(null)
}
</script>

<style scoped>
h5 {
  padding: 9px 12px;
  line-height: 22px;
  height: 48px;
}
.star-wrap svg { color: rgb(0, 169, 204); }

h5 > * > svg {
  height: 22px;
}

h5 > * > svg + * {
  margin-left: 6px;
}

h5 > * > .badge {
  height: 22px;
  min-width: 23px;
  font-size: 16px;
  padding: 2px 3px;
  margin-left: 6px;
}

h5 > * > * {
  margin-top: 0;
}

.modal-body {
  height: 716px;
  background-repeat: no-repeat;
  background-size: contain;
}

.box-400 {
  width: 100%;
  height: 400px;
}

.left-col {
  min-height: 590px;
}

.photo-frame {
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  min-height: 400px;
  height: 400px;
}

.th-crtr-name {
  padding-top: 5px;
  padding-bottom: 6px;
}

.th-repu {
  height: 38px;
  padding-bottom: 7px;
}
.th-repu > * {
  line-height: 22px;
}

.repu-cnt {
  min-width: 22px;
  height: 22px;
}

.prof-img-deco {
  position: absolute;
  right: -36px;
  bottom: 0;
  width: 128px;
}

.table {
  min-height: 145px;
}

.table > tbody > * > td, .table > tbody > * > th {
  font-size: 0.9rem;
  background-color: transparent;
}

th {
  background-color: white;
  font-weight: bold;
}

.photo-grid-view {
  height: calc(100% - 41px);
  display: -webkit-box;
}

.table tbody th,
.table tbody td {
  height: 38px;
  vertical-align: middle;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.table tbody th {
  padding-left: 0.75rem;
}

tr th svg {
  margin-right: 4px;
}

.table > :not(caption) > * > * {
  box-shadow: inherit;
}

.table > tbody > tr:not(:last-child) > td.inner-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.table > tbody > tr:last-child > td {
  border: none;
}

.td-reput > svg {
  color: rgb(0, 169, 204);
}

.btn-pos-fix-btm .btn {
  display: flex;
  font-weight: 500;
  padding-left: 12px;
  font-size: 1.35rem;
}

.btn-pos-fix-btm .btn + .btn {
  margin-left: 1rem;
}

.btn-pos-fix-btm .btn .text {
  margin-left: 0.5rem;
}

.badge { min-width: 20px; padding: 1px 3px; }
.badge-wrap  {
  min-width: 27px;
  position: relative;
}

.badge-wrap .badge {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 22px;
}
.badge.h-slim {
  line-height: 1;
  text-shadow: -1px 0 rgba(0,0,0,0.3), 0 1px rgba(0,0,0,0.3), 1px 0 black, 0 -1px rgba(0,0,0,0.3);
  box-shadow: rgba(0, 0, 0, 0.3) 1px 3px 3px;
}
.self-itro-wrap {
  padding: 7px 10px;
}
@media (max-width: 991px) {
  .modal-content {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .right-col {
    margin-bottom: 64px;
  }

}

@media (min-width: 992px) {
  .right-col {
    border-left: 1px solid #444;
  }

  .crtr-row {
    height: 100%;
  }

  .h5-titl {
    padding-right: 64px;
  }
  .crtr-actn-btns {
    width: 66.66% !important;
    border-left: 1px solid gray;
  }
}
</style>
