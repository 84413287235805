import { defineStore } from 'pinia'

type ComnCode = {
  code: string,
  name: string,
  valu: string
}

export const cnst_stor = defineStore({
	id: 'constant',
	state: () => ({
    comnCode: comn?comn.getComnCode():null,
    ctryCode: comn?comn.getCtryCode():null,
    ctgrCode: comn?comn.getCtgrCode():null,
    stleCode: comn?comn.getStilCode():null,
		COMPANY_NAME: ["Generated", "Bitflow"],
    COMPANY_NAME_SUFFIX: ", Inc.",
		COMPANY_ADDR: ["651 N Broad St, Middletown, DE, USA", "Dongdaegu-ro, Dong-gu, Daegu, KR"],
	}),
	getters: {
		getCompanyName: (state) => {
			const loc = sessionStorage.getItem('tl')
			let idx = 0
			if (loc && loc.toUpperCase()==='UK') {
				idx = 1
			}
			return state.COMPANY_NAME[idx]
		},
		getCompanyAddr: (state) => {
			return state.COMPANY_ADDR[0]
		},
		getArtCategories: (state) => {
			return state.ctgrCode
		},
		getArtStiles: (state) => {
			return state.stleCode
		},
		getAllCodes: (state) => {
			return state.comnCode
		},
		getCountries: (state) => {
			return state.ctryCode
		},
	},
	actions: {
    getCtryName(cc: string) {
      if (this.ctryCode && this.ctryCode.length > 0) {
        for (const item of this.ctryCode) {
          if (item.iso2===cc) {
            return item.name
          }
        }
      }
      return null
    },
		getCodeGrup(codeGrup: string) {
      if (this.comnCode) {
			  return this.comnCode[codeGrup]
      } else {
        return null
      }
		},
		getCodeName(grupCode: string, code: string) {
			let ret = ''
      this.getCodeGrup(grupCode).forEach((it: ComnCode) => {
				if (it.code === code) {
					ret = it.name
				}
			})
			return ret
		},
		getCodeValu(grupCode: string, code: string) {
			let ret = ''
      this.getCodeGrup(grupCode).forEach(function (it: ComnCode) {
				if (it.code === code) {
					ret = it.valu
				}
			})
			return ret
		},
		getGenToolModlNm(code: string, name: string) {
			if (name && name.length > 0) {
				return name
			}
			if (!code || code.length < 5) { return '' }
			return this.getCodeName(code.substring(0, 4), code)
		}
	}
})
