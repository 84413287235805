import { createRouter, createWebHistory } from 'vue-router'
import { user_stor } from '@/stor/user_stor'
import { BrwsStrgKey } from '@/util/comn_cnst'
import { copyLoclToSess } from '@/util/comn_util'
import { getToknExprTimeInMinSec, rfshToknSyncFull } from '@/util/auth_func'

const requireAuth = () => (from, to, next) => {
  if (user_stor().isSignedIn) {
    next()
  } else {
    const minLeft = getToknExprTimeInMinSec()[0]
    console.log(`[RqstAuth] min-left ${minLeft}, from router`, from.path)
    const tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
    const yes = localStorage.getItem(BrwsStrgKey.STOP_TOKN_RQST)
    if (minLeft > 0) {
      // 시간 남았으면 리프레시
      rfshToknSyncFull()
    } else if (tp && tp.length > 9 && yes && yes === '1') {
      // 시간은 없는데 새창 같은 경우
      console.log('[RqstAuth] may be new tab')
      copyLoclToSess()
      rfshToknSyncFull()
    } else {
      // 시간 끝난 경우
      console.log('[RqstAuth] no auth but next')
      user_stor().clerUserData()
      next('/view/sign/in?r=' + encodeURIComponent(from.path))
      // next('/view/sign/in')
    }
  }
}

const routes = [
	{
		path: '/',
		name: 'main',
		component: () => import('@/view/MainView.vue'),
	},
	{
		path: '/view/rank',
		name: 'crtrRank',
		component: () => import('@/view/RankView.vue'),
	},
  {
    path: '/view/rank/:perd',
    name: 'crtrRankByPerd',
    component: () => import('@/view/RankView.vue'),
  },
  {
    path: '/view/evnt',
    name: 'siteEvnt',
    component: () => import('@/view/EvntView.vue'),
  },
	{
		path: '/view/polc/faq',
		name: 'faq',
		component: () => import('@/view/FaqView.vue')
	},
	{
		path: '/view/polc/pric',
		name: 'pric',
		component: () => import('@/view/PricView.vue')
	},
	{
		path: '/view/polc/tos',
		name: 'tos',
		component: () => import('@/view/TermOfSrvc.vue')
	},
	{
		path: '/view/polc/prvc',
		name: 'prvc',
		component: () => import('@/view/PrvtPolc.vue')
	},
	{
		path: '/view/polc/crtr',
		name: 'crtrCsnt',
		component: () => import('@/view/CrtrCsnt.vue')
	},
	{
		path: '/view/polc/rlnt',
		name: 'relsNote',
		component: () => import('@/view/RelsNote.vue')
	},
	{
		path: '/view/pmpt/imgs/:grupId',
		name: 'imgDetl',
		// components: {
		// 	modal: () => import('@/view/modal/ImgWorkDetlModal.vue')
		// }
	},
  {
    path: '/view/pmpt/imgs',
    name: 'pmptImgList',
    component: () => import('@/view/ImgWorkView.vue'),
    props: true,
    children: []
  },
  {
    path: '/view/gen',
    name: 'avilTool',
    component: () => import('@/view/laot/GenLaot.vue'),
    children: [
      {
        path: 'img',
        name: 'genImg',
        component: () => import('@/view/GenImgGen.vue')
      },
      {
        path: 'upsc',
        name: 'upsc',
        component: () => import('@/view/GenUpsc.vue')
      },
      {
        path: 'bgrm',
        name: 'bgrm',
        component: () => import('@/view/GenBgrdRemv.vue')
      },
      {
        path: 'gnfl',
        name: 'gnfl',
        component: () => import('@/view/GenGnrtFill.vue')
      },
    ]
  },
  {
    path: '/view/pmpt/pamt',
    name: 'pamtRslt',
    component: () => import('@/view/StrpPamtSucc.vue')
  },
  {
    path: '/view/pmpt/dnld/:id',
    name: 'gestDnld',
    component: () => import('@/view/DnldView.vue'),
    props: true
  },
	{
		path: '/view/sign',
		name: 'user',
		component: () => import('@/view/SignView.vue'),
		children: [
			{
				path: 'in',
				name: 'signIn',
				component: () => import('@/view/pane/SignInPane.vue')
			},
			{
				path: 'up',
				name: 'signUp',
				component: () => import('@/view/pane/SignUpPane.vue')
			},
      {
        path: 'vrfy',
        name: 'vrfyEmal',
        props: true,
        component: () => import('@/view/pane/SignUpPinCodePane.vue')
      },
			{
				path: 'pswd/:userId',
				name: 'rgstPswd',
				props: true,
				component: () => import('@/view/pane/SignUpSetPswdPane.vue')
			}
		]
	},
  {
    path: '/view/sign/ok/:id',
    name: 'vrfySucc',
    props: true,
    component: () => import('@/view/SignPinCodeSucc.vue')
  },
	{
		path: '/view/rgst/imgs',
		name: 'rgstImgs',
		component: () => import('@/view/ImgWorkRgst.vue'),
		children: [
			{
				path: 'stp1',
				name: 'rgstStep1',
				component: () => import('@/view/pane/ImgWorkRgstStep1Pane.vue')
			},
			{
				path: 'stp2',
				name: 'rgstStep2',
				component: () => import('@/view/pane/ImgWorkRgstStep2Pane.vue'),
				beforeEnter: requireAuth()
			}
		]
	},
	{
		path: '/view/user',
		name: 'myPage',
		component: () => import('@/view/laot/MyPageLaot.vue'),
		beforeEnter: requireAuth(),
		children: [
			{
				path: 'dsbd',
				name: 'myDsbd',
				component: () => import('@/view/MyPage.vue')
			},
			{
				path: 'vrfy',
				name: 'myVrfy',
				component: () => import('@/view/MyVrfy.vue'),
				children: [
					{
						path: 'itro',
						name: 'vrfyItro',
						component: () => import('@/view/pane/VrfyIntr.vue')
					},
					{
						path: 'stp1',
						name: 'vrfyStep1',
						component: () => import('@/view/pane/VrfyStep1.vue')
					},
					{
						path: 'papl',
						name: 'vrfyPapl',
						component: () => import('@/view/pane/VrfyPapl.vue')
					},
					{
						path: 'strp',
						name: 'vrfyStrp',
						component: () => import('@/view/pane/VrfyStrp.vue')
					},
					{
						path: 'wlet',
						name: 'vrfyWlet',
						component: () => import('@/view/pane/WletPane.vue')
					}
				]
			},
			{
				path: 'crtr',
				name: 'myCrtrAtvt',
				component: () => import('@/view/MyCret.vue'),
			},
			{
				path: 'incm',
				name: 'myIncm',
				component: () => import('@/view/MyIncm.vue')
			},
			{
				path: 'cupn',
				name: 'myCupn',
				component: () => import('@/view/CupnView.vue')
			},
		]
	},
	{
		path: '/view/err',
		name: 'NotFund',
		component: () => import('@/view/ErorView.vue')
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/view/err'
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
	}
})

router.beforeEach(async (to, from, next) => {
	console.log(`[BFOR-EACH] from: ${from.path}, to: ${to.path}`)
  if (to.path && to.path.endsWith('null')) {
    to.path = to.path.replace('null', '')
  } else if (to.path && to.path.endsWith('undefined')) {
    to.path = to.path.replace('undefined', '')
  } else if (to.path && to.path.indexOf('/pmpt/imgs/')>-1) {
    // 외부로 부터 이미지 상세모달 접근 시
    if (!user_stor().getPmptImgGrupId) {
      sessionStorage.setItem('fromExternal', 'Y')
      user_stor().openImgDetlPopUp(to.params.grupId, to.query.d)
      next(false)
      return
    }
  }
	next()
})

export default router
