<template>
  <!-- modal bg (img detail) -->
  <div class="modal" tabindex="-1">
    <!-- modal dialog-->
    <div class="modal-dialog modal-xl bg-dark">
      <!-- modal content-->
      <div id="modal-content" class="modal-content">
        <!-- modal body-->
        <div id="img-modal-body" class="modal-body">

          <img-work-detl-pane
            ref="detlPane"
            v-if="model.step === 1 && model.data"
            @showRprtMdal="showRprtMdal"
            :data="model.data"
            :mode="model.mode"
            @setCurrItemId="setCurrItemId"
          />

          <img-work-pcse-stp1-pane
            ref="pamtPane"
            v-if="model.step === 2 || model.step === 3"
            :data="model.data"
            @onCartChng="onCartChng"
            :payData="model.payData"
            @onPaySuccess="onPamtSucc"
            @onAddCart="onAddCart"
            :step="model.step"
            :releaseUi="model.releaseUi"
            @onStep2NextClick="onStep2NextClick"
            @onPrev="onPrev"
            @closMdal="closMdal"
          />

        </div>

        <!-- button area : detail page -->
        <abs-bttm-btns-area
          v-if="!model.loading && model.step === 1 && model.data && model.mode==null"
          :btnUse="['maxm', 'quot', 'shre', 'like', 'pcse']"
          :liked="model.grup && model.grup.liked"
          hideCnclBtn="true"
          class="flexible m-brdr"
          @onFullScrnClck="onFullScrnClck"
          @onLike="onLike"
          @onShre="onShre"
          @onQuot="onQuot"
          @onPcseBtnClck="onPcseBtnClck"
        />
        <!-- button area : detail page -->
      </div>
      <!-- modal content -->

      <div v-if="model.brkn" class="pos-abs-center">
        <img src="@/assets/img/icon/ico-file-not-fund.png" />
      </div>

      <btn-close-top-right class="white" @onCloseClick="closMdal" />
      <spnr-no-block class="loading" v-if="model.loading" theme="absolute white" />

    </div>

    <rprt-modal
      v-if="model.showRprtMdal"
      @closeModal="closRprtMdal"
      :grupId="model.grup.grupId"
      :crtrId="model.grup.crtrId"
    />

  </div>
  <!-- modal bg (img detail) -->

  <cite-prvw-modal v-if="model.citeShow" :cite-info="model.citeInfo" @closeModal="model.citeShow=false"/>

</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive, watch, ref } from "vue"
import axis_cstm from "@/util/axis_cstm"
import { user_stor } from "@/stor/user_stor"
import { ResCd, API_ROOT, ArtRgstStep } from "@/util/comn_cnst"
import BtnCloseTopRight from "@/view/btns/BtnCloseTopRight.vue"
import AbsBttmBtnsArea from "@/view/btns/AbsBttmBtnsArea.vue"
import ImgWorkDetlPane from "@/view/pane/ImgWorkDetlPane.vue"
import ImgWorkPcseStp1Pane from "@/view/pane/ImgWorkPcseStep1Pane.vue"
import router from "@/rout"
import {
  blockRefresh,
  preventBgScroll,
  releaseBgScroll,
  unblockRefresh
} from "@/util/evnt_func"
import SpnrNoBlock from "@/view/pgrsAlrt/SpnrNoBlock.vue"
import RprtModal from "@/view/modal/RprtModal.vue"
import { mdal_stor } from '@/stor/mdal_stor'
import CitePrvwModal from '@/view/modal/CitePrvwModal.vue'

const mdal = mdal_stor()
const emit = defineEmits(["closeModal"])
const prop = defineProps(["grupId", "rgstStepList"])
const user = user_stor()
const pamtPane = ref()
const detlPane = ref()
const model = reactive({
  loading: true,
  mode: null,
  d: null,
  grup: null,
  grupId: null,
  step: 1,
  brkn: false,
  data: null,
  fromExternal: false,
  imgList: [],
  user: null,
  disabled: true,
  hideCnclBtn: true,
  payData: {},
  rgstStep: "",
  showRprtMdal: false,
  releaseUi: false,
  citeInfo: null
})

watch(
  () => model.step,
  (param) => {
    model.step = param
  }
)

onMounted(() => {

  const fromExt = sessionStorage.getItem('fromExternal')
  if (fromExt && fromExt==='Y') {
    model.fromExternal = true
  } else {
    model.fromExternal = false
  }
  sessionStorage.removeItem('fromExternal')
  window.addEventListener('popstate', onBackPres)

  blockRefresh()
  if (document.body.offsetWidth < 992) {
    preventBgScroll()
  }

  let grupId = user.getPmptImgGrupId && user.getPmptImgGrupId.length > 0 ? user.getPmptImgGrupId[0] : null
  if (grupId && grupId.length > 0) {
    // 1) state로 넘어오는 경우
    console.log(`[IMG-DETL-POP] opened by router, external: ${model.fromExternal}, host: ${location.hostname}`)
    model.grupId = user.getPmptImgGrupId[0]
    let d = ''
    if (user.getPmptImgGrupId[1]) {
      model.d = user.getPmptImgGrupId[1]
      d = '?d=' + user.getPmptImgGrupId[1]
    } else {
      model.d = null
    }
    if (user.getPmptImgGrupId[2]) {
      model.rgstStep = user.getPmptImgGrupId[2]
    } else {
      model.rgstStep = null
    }
    history.pushState({}, null, `/view/pmpt/imgs/${model.grupId}${d}`)
  } else {
    // 2) URL로 바로 넘어오는 경우
    console.log(`[IMG-DETL-POP] opened by direct URL access, external: ${model.fromExternal}, host: ${location.hostname}`)
    model.grupId = location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
    const params = new URL(location.href).searchParams
    model.d = params && params.get("d") ? params.get("d") : null
    model.rgstStep = ArtRgstStep.APPROVED
  }
  if (model.grupId) {
    getImgDetl(model.grupId)
  }
})

onBeforeUnmount(() => {
  unblockRefresh()
  window.removeEventListener('popstate', onBackPres)
  if (document.body.offsetWidth < 992) {
    releaseBgScroll()
  }
})

function closRprtMdal() {
  model.showRprtMdal = false
}

function showRprtMdal() {
  model.showRprtMdal = true
}

function setCurrItemId(itemId) {
  if (itemId) {
    model.d = itemId
    console.log("setCurrItemId", itemId)
  }
}

// Step2 -> Step3
// customize checkout page: https://docs.stripe.com/checkout/embedded/quickstart#customize-toggle
async function onStep2NextClick() {
  //console.log("[Step2] Next click", JSON.stringify(model.payData))
  // 1) if member purchase
  if (user.isSignedIn) {
    // Member purchase
    await axis_cstm()
      .get(API_ROOT + "prvt/my/basc")
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          model.payData["user"] = data.rslt
        }
        model.step = 3
      })
      .catch((err) => {
        console.error("err", err)
      })
      .finally(() => {})
  } else {
    model.step = 3
  }

}

function onQuot() {
  // pblcThumUrl (x) pblcWtmkUrl
  model.citeInfo = {
    crtrNm: model.grup.crtrNm,
    titl: model.grup.titl,
    date: model.grup.aprvDttm.substring(0, 4),
    host: location.host,
    imgUrl: detlPane.value.getWtmkUrl(),
    refUrl: location.href,
  }
  console.log('data', JSON.stringify(model.citeInfo))
  model.citeShow = true
}

function onAddCart() {
  if (!user.isSignedIn) {
    mdal.confirm(
      "You must be signed in to add to cart.<br/>Are you sure you want to sign in?",
      function () {
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        router.push(
          "/view/sign/in?r=" + encodeURIComponent(`/view/pmpt/imgs/${model.grup.grupId}${imgPath}`)
        )
      },
      '<i class="fa-solid fa-check me-2"></i>Sign in'
    )
  } else {
    let totlPric = (model.payData.imgPricAmt * model.payData.imgCnt + model.payData.pmptPricAmt) / 100
    //Todo
    // ctry 추가
    const param = {
      grupId: model.grup.grupId,
      items: model.payData.items,
      imgIdList: model.payData.imgIdList,
      totlPric: totlPric,
      ctry: 'US',
      crcy: sessionStorage.getItem('crcy'),
      imgCnt: model.payData.imgCnt,
      pmptCnt: model.payData.pmptCnt
    }
    axis_cstm()
      .post(API_ROOT + "prvt/cart", param)
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.REGISTERED) {
          user.updtCartCnt(1)
          mdal.toast('<i class="fa-solid fa-cart-shopping me-2 text-primary"></i> Added to cart!')
        }
      })
      .catch((err) => {
        console.error("err", err)
      })
      .finally(() => {})
  }
}

/**
 * Definition
 * @param param
 */
function onCartChng(param) {
  // 그룹ID, 타이틀, 이미지ID 리스트, 이미지가격ID/이름, 프롬프트가격ID/이름
  // , 할인ID, 할인률,
  if (param==null) {
    console.log('[modal] onCartChng paydata null')
    model.payData = null
  } else {
    console.log('[modal] onCartChng paydata', JSON.stringify(param))
    model.payData = param
  }
}

function onFullScrnClck() {
  detlPane.value.setFullScrn(true)
}

function onPcseBtnClck() {
  if (user.isSignedIn && user.isSignedIn===true) {
    //console.log('[MMBR-USER]')
    if (model.data.grup.purchased) {
      mdal.confirm(
        "This item has been purchased<br/>in whole or in part.<br/>Do you still want to continue?",
        function () {
          model.step = 2
        },
        '<i class="fa-solid fa-check me-2"></i>OK',
        null,
        '<i class="fa-solid fa-xmark-large me-2"></i>Cancel'
      )
    } else {
      model.step = 2
    }
  } else {
    console.log('[GUEST-USER]')
    mdal.confirm(
      "We recommend you sign in<br/>before making a purchase.<br/>Do you want to sign in?",
      function () {
        user.closImgDetlPopUp()
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        location.href =
          "/view/sign/in?r=" + encodeURIComponent(`/view/pmpt/imgs/${model.grup.grupId}${imgPath}`)
      },
      '<i class="fa-solid fa-person-running-fast me-2"></i>Sign in',
      function () {
        // When 2nd button (cancel) clicked
        model.step = 2
      },
      '<i class="fa-regular fa-user-slash me-2"></i>As a guest'
    )
  }
}

/**
 * e.currentTarget.history.state.back
 * e.target.history.state.back
 * @param e
 */
function onBackPres(e) {
  console.log('onBackPres', e)
  if (model.fromExternal) {
    router.push('/view/pmpt/imgs')
  } else {
    user.closImgDetlPopUp()
  }
}

function closMdal(forceClose) {
  console.log(`closMdal(${forceClose})`)
  if (forceClose && forceClose===true) {
    user.closImgDetlPopUp()
  } else if (model.step === 3) {
    mdal.confirm(
      "You're going through<br/>the checkout process.<br/>Are you sure you want to stop?",
      function () {
        user.closImgDetlPopUp()
        location.reload()
      },
      '<i class="fa-solid fa-check me-2"></i>Yes',
      null,
      '<i class="fa-solid fa-xmark-large me-2"></i>Cancel'
    )
  } else {
    if (model.fromExternal) {
      router.push('/view/pmpt/imgs')
      user.closImgDetlPopUp()
    } else {
      // router back keep scroll position
      router.go(-1)
    }
  }
}

function onPrev() {
  console.log("onPrev", model.step)
  model.step--
  if (model.step === 2) {
    model.releaseUi = true
  } else {
    model.step = 1
  }
}

function closCiteMdal() {
  model.citeShow = false
}

function onPamtSucc(param) {
  // ref. https://developer.paddle.com/api-reference/notification-settings/create-notification-setting
  // Todo: 1) Send payment data to server or get by webhook(Notifications)
  // Todo: 2) then router.push
  console.log("[onPamtSucc]", JSON.stringify(param))
  mdal.confirm(
    "Payment has been completed.<br/>Would you like to go to the order list?",
    function () {
      user.closImgDetlPopUp()
      router.push("/view/user/dsbd")
    },
    "Yes",
    closMdal
  )
}

function onShre() {
  const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
  window.navigator.clipboard
    .writeText(import.meta.env.VITE_APP_HOST + `/view/pmpt/imgs/${model.grup.grupId}${imgPath}`)
    .then(() => {
      mdal.toast(
        '<i class="fa-regular fa-copy me-2"></i>The content URL to<br/>share has been copied<br/>to the clipboard'
      )
    })
  // The content URL to share is<br/> copied to the clipboard.
}

function onLike() {
  if (!user.isSignedIn) {
    mdal.confirm(
      "Sign in is required.<br/>Would you like to sign in?",
      function () {
        user.closImgDetlPopUp()
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        location.href =
          "/view/sign/in?r=" + encodeURIComponent(`/view/pmpt/imgs/${model.grup.grupId}${imgPath}`)
      },
      "<i class='fa-solid fa-check me-2'></i> Sign in"
    )
  } else {
    axis_cstm()
      .post(API_ROOT + "prvt/pmpt/like", { grupId: model.grup.grupId, ctntType: "image" })
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          if (data.rslt === true) {
            mdal.toast("<i class='fa-solid fa-thumbs-up me-2 text-primary'></i> Added to like!")
            model.grup.liked = true
          } else {
            mdal.toast(
              "<i class='fa-regular fa-trash-xmark me-2 text-danger'></i> Removed from like!"
            )
            model.grup.liked = false
          }
        }
      })
      .catch((err) => {
        console.error("err", err)
      })
      .finally(() => {})
  }
}

function getImgDetl(grupId) {

  const axios = axis_cstm()
  const tl = sessionStorage.getItem("tl")
  const c1 = sessionStorage.getItem("crcy")
  // console.log("tl", tl, "c1", c1)
  if (tl && tl.length === 2) {
    axios.defaults.headers["tl"] = tl
  }
  if (c1 && c1.length === 2) {
    axios.defaults.headers["c1"] = c1
  }

  let midlPath = "prvt"
  let lastPath = ''
  if (!model.rgstStep) {
    midlPath = "pblc"
  } else {
    // !important
    lastPath = (model.rgstStep===ArtRgstStep.REJECTED)?'/rejt'
      :(model.rgstStep===ArtRgstStep.WORKING_1)?'/wrkg':(model.rgstStep===ArtRgstStep.REQUESTED)?'/wait':'/aprv'
    model.mode = lastPath.substring(1)
  }
  // console.log(`[PMPT_IMG_MODL] ${midlPath}/pmpt/imgs/${grupId}${lastPath}`)
  model.loading = true
  axios
    .get(API_ROOT + `${midlPath}/pmpt/imgs/${grupId}${lastPath}`)
    .then((res) => {
      //console.log('res', JSON.stringify(res))
      if (res.status===204) {
        model.brkn = true
        return
      }
      const data = res.data
      if (data.code === ResCd.SUCCESS) {
        if (!data.rslt) {
          mdal.confirm(
            "Failed to load data,<br/>would you like to try again?",
            function () {
              getImgDetl(prop.grupId)
            },
            '<i class="fa-solid fa-check me-2"></i>OK',
            closMdal
          )
          return
        } else if (data.rslt.hasPerm === false) {
          mdal.alert("You don't have permission<br/> to view the content.", closMdal)
          return
        }
        let idx = 0
        let imgIdx = 0
        if (data.rslt.imgList) {
          if (model.d) {
            data.rslt.imgList.forEach((it) => {
              if (it.itemId === model.d) {
                imgIdx = idx
              }
              idx++
            })
          } else {
            imgIdx = 0
          }
        }
        model.data = data.rslt
        model.grup = data.rslt.grup
        model.imgList = data.rslt.imgList
        model.grup.index = imgIdx
        model.data.index = imgIdx
      }
    })
    .catch((err) => {
      console.error("err", err)
    })
    .finally(() => {
      model.loading = false
    })
}
</script>

<style scoped>
.modal-content {
  border: none;
  background-color: transparent;
}
.modal-body {
  overflow-x: hidden;
  background-color: rgb(33, 37, 41);
}
.img-page > svg {
  font-size: 1.7rem;
  margin-right: 0.5rem;
}

.img-page > div {
  padding-bottom: 0.5rem;
  line-height: 1.4rem;
}

.img-detail hr {
  color: #75b798;
  opacity: 1;
  border-width: 5px;
}

span:last-child {
  margin-right: 0;
}

.creator > svg {
  font-size: 18px;
  height: 18px;
  margin-right: 6px;
  color: #555;
}

@media (min-width: 992px) {
  .modal-content {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}

@media (min-width: 992px) {
  .modal-body {
    overflow-y: hidden;
  }
  .modal-content {
    overflow: hidden;
  }
}
</style>
