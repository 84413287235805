<template>
  <!--  aria-live="polite" aria-atomic="true" -->
  <div class="modal-dialog modal-xs modal-dialog-centered" :class="model.class">
    <div class="modal-content">
      <div class="modal-header">
        <img src="/aset/logo-gen8-wide-navbar.webp" class="logo-icon rounded me-auto" />
      </div>
      <div class="modal-body mx-2">
        <h5 class="text-center text-primary blink" v-html="model.msg"></h5>
      </div>
      <btn-close-top-right @click="model.class=''" />
    </div>
  </div>
</template>

<script setup>
// ref. https://codepen.io/nttoan1202/pen/OJzvQQN
import { reactive, watch } from 'vue'
import BtnCloseTopRight from '@/view/btns/BtnCloseTopRight.vue'
import { mdal_stor } from '@/stor/mdal_stor'

const mdal = mdal_stor()
const model = reactive({
  msg: null,
  class: ''
})

watch(
  () => mdal.getTostMsg,
  (param) => {
    if (param && param.length > 0) {
      model.class = 'show'
      model.msg = param
      setTimeout(() => {
        model.class = ''
        mdal.toast(null)
      }, 3500)
    } else {
      model.class = ''
      mdal.toast(null)
    }
  }
)
</script>

<style scoped>
.logo-icon {
  height: 48px;
}

.modal-dialog {
  position: fixed;
  z-index: 1200;
  top: 180px;
  left: 100%;
  min-width: 360px;
  max-width: calc(100% - 6rem);
  min-height: fit-content;
  max-height: calc(100% - 1rem);
  transform: translateX(50%);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
}

.modal-body {
  padding: 0 0 51px 0;
  display: grid;
}

h5 {
  line-height: 32px;
  height: fit-content;
  margin: auto;
  font-size: 1.4rem;
  font-weight: normal;
}

.modal-dialog.show {
  transform: translateX(calc(-100% - 1.5rem));
}

@media (max-width: 767.98px) {
  .modal-dialog.show {
    transform: translateX(calc(-100% - 1.5rem));
  }
}
</style>
