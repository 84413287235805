<template>
	<div id="btn-fold" class="btn btn-fold btn-arrow-outline" :class="(!model.opened?`r-180 ${model.theme}`:`${model.theme}`)"
       role="button" @click="toggleButton">
  </div>
</template>

<script setup>

import { onMounted, reactive, watch } from 'vue'

const prop = defineProps(['opened', 'theme'])
const emit = defineEmits(['btnFoldChanged'])
const model = reactive({
	opened: false,
  theme: ''
})

watch(
	() => prop.opened,
	(param) => {
		model.opened = param
})

onMounted(() => {
  model.opened = prop.opened
  if (prop.theme) {
    model.theme = prop.theme
  }
})

function toggleButton() {
  model.opened = !model.opened
  emit('btnFoldChanged', model.opened)
}
</script>
<style>
.btn-fold {
  width: 29px;
  height: 29px !important;
}
</style>
