import axis_cstm from '@/util/axis_cstm'
import { API_ROOT, BrwsStrgKey, ResCd } from '@/util/comn_cnst'
import { user_stor } from '@/stor/user_stor'
import MobileDetect from 'mobile-detect'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

String.prototype.format = function () {
  var formatted = this
  for (var arg in arguments) {
    formatted = formatted.replace('{' + arg + '}', arguments[arg])
  }
  return formatted
}

// https://dev.fingerprint.com/docs/quick-start-guide
const fpPromise = FingerprintJS.load()

async function getFingerprintAsync() {
  return fpPromise.then((fp) => fp.get()).then((res) => res.visitorId)
}

/**
 * Refactored & confirmed
 * @returns {Promise<*>}
 */
function genFingerprint() {
  // 1. Gen fingerprint + ip mix
  let tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
  if (tp && tp.length > 9) {
    return tp
  } else {
    fpPromise
      .then((fp) => fp.get())
      .then((res) => {
        let tp1 = res.visitorId
        let ip = '127.0.0.1'
        fetch('/cdn-cgi/trace').then((res) => {
          if (res.data) {
            let lines = res.data.split('\n')
            let keyValue, trace
            lines.forEach(function (line) {
              keyValue = line.split('=')
              trace[keyValue[0]] = decodeURIComponent(keyValue[1] || '')
              //   localStorage.setItem('tz', data.rslt.tz)
              if (keyValue[0] === 'ip' && trace['ip']) {
                ip = trace['ip']
              }
              if (keyValue[0] === 'loc' && trace['loc'] !== 'XX') {
                localStorage.setItem(BrwsStrgKey.USER_LOCATION, trace['loc'])
              }
            })
          }
          tp = tp1 + ip.replace(/[.]/gi, '9').replace(/[:]/gi, '8')
          console.log('[TP]', tp)
          localStorage.setItem(BrwsStrgKey.FNGRPRNT_PLUS, tp)
        }).catch((e) => {
          tp = tp1 + ip.replace(/[.]/gi, '9').replace(/[:]/gi, '8')
          console.log('[TP]', tp)
          localStorage.setItem(BrwsStrgKey.FNGRPRNT_PLUS, tp)
        })
      })
  }
}

function getCartCnt() {
  if (user_stor().isSignedIn) {
    axis_cstm()
      .get(API_ROOT + 'prvt/cart/cont')
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          user_stor().setCartCnt(data.rslt)
        }
      })
      .catch((err) => {
        console.log(`${err.name} ${err.code} ${err.message}`)
      })
      .finally(() => {})
  }
}

function getNotiList() {
  if (user_stor().isSignedIn) {
    const noti = sessionStorage.getItem(BrwsStrgKey.NOTI_DATA)
    if (noti && noti.length > 9) {
      user_stor().setAncmInfo(JSON.parse(noti))
    } else {
      axis_cstm()
        .get(API_ROOT + 'prvt/my/noti')
        .then((res) => {
          const data = res.data
          if (data.code === ResCd.SUCCESS && data.rslt) {
            user_stor.set
          }
        })
        .catch((err) => {
          console.log(`${err.name} ${err.code} ${err.message}`)
        })
        .finally(() => {})
    }
  }
}

function isMobile() {
  const md = new MobileDetect(window.navigator.userAgent)
  const device = md.mobile()
  const ret = (device !== null && device.length > 0)
  // console.log('isMobile', ret)
  return ret
}

function getAbstPos(el) {
  if (!el) {
    return
  }
  var rect = el.getBoundingClientRect()
  return { left: rect.left, top: rect.top }
}

export {
  getFingerprintAsync,
  genFingerprint,
  getCartCnt,
  getNotiList,
  isMobile,
  getAbstPos,
}
