<template>
	<div class="modal" tabindex="-1">
		<div class="loading-wrapper">
			<div class="loading-inner" :class="mdal.getLodrThem?mdal.getLodrThem:''">
				<img v-if="!mdal.getLodrThem || mdal.getLodrThem==='black'" :src="blackLoader" />
				<img v-if="mdal.getLodrThem && mdal.getLodrThem==='white'" :src="whiteLoader" />
				<div v-if="!prop.hideText" class="lodn-text text-center" v-html="model.mesg"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import blackLoader from '@/assets/img/icon/ic-loading-b.webp'
import whiteLoader from '@/assets/img/icon/ic-loading-w.webp'
import { onMounted, reactive, watch } from 'vue'
import { mdal_stor } from '@/stor/mdal_stor'

const mdal = mdal_stor()
const prop = defineProps(['theme', 'mesg', 'hideText'])
const model = reactive({
	mesg: 'Loading'
})

watch(() => prop.mesg,
	(param) => {
  model.mesg = param
})

onMounted(() => {
	if (prop && prop.mesg) {
		model.mesg = prop.mesg
	}
})
</script>
