// refer1: https://stackabuse.com/validate-email-addresses-with-regular-expressions-in-javascript/
// refer2: https://www.abstractapi.com/guides/email-validation-regex-javascript
// refer3: https://www.w3resource.com/javascript/form/email-validation.php
import { BrwsStrgKey, PaotTrnsStat, AuthPvdr, API_ROOT, ResCd } from '@/util/comn_cnst'
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'
import { user_stor } from '@/stor/user_stor'
import { genFingerprint } from '@/util/comn_func'
import axis_cstm from '@/util/axis_cstm'
import { rfshToknSyncFull } from '@/util/auth_func'

// Upper+Lower combination is required
// export const regexPasswordUpperLower = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,15}$/
const regexPasswordUpperLower = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/
)
const regexEmail = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
const pxNum2Str = (num) => {
  let ret = 0
  if (num >= 1000000000) {
    ret = Math.round(num / 100000000) / 10 + 'GP'
  } else if (num >= 1000000) {
    ret = Math.round(num / 100000) / 10 + 'MP'
  } else if (num >= 1000) {
    ret = Math.round(num / 1000) + 'KP'
  } else {
    ret = num + 'P'
  }
  return ret
}
const pxNum2StrMidl = (num) => {
  let ret = 0
  if (num >= 1073741824) {
    ret = Math.round(num / 107374182.4) / 10 + 'GPx'
  } else if (num >= 1048576) {
    ret = Math.round(num / 104857.6) / 10 + 'MPx'
  } else {
    ret = Math.round(num / 102.4) / 10 + 'KPx'
  }
  return ret
}

const pxNum2StrLong = (num) => {
  let ret = 0
  if (num >= 1000000000) {
    ret = Math.round(num / 100000000) / 10 + 'GPixel'
  } else {
    //  if (num >= 1000000)
    ret = Math.round(num / 100000) / 10 + 'MPixel'
  }
  return ret
}

const byteNum2StrShrt = (num) => {
  let ret = 0
  if (num >= 1073741824) {
    ret = Math.round(num / 107374182.4) / 10 + 'GB'
  } else if (num >= 1048576) {
    ret = Math.round(num / 104857.6) / 10 + 'MB'
  } else {
    ret = Math.round(num / 102.4) / 10 + 'KB'
  }
  return ret
}

const byteNum2StrLong = (num) => {
  let ret = 0
  if (num >= 1000000000) {
    ret = Math.round(num / 100000000) / 10 + 'GBytes'
  } else {
    //  if (num >= 1000000)
    ret = Math.round(num / 100000) / 10 + 'MBytes'
  }
  return ret
}

// ref. https://developer.mozilla.org/en-US/docs/Glossary/Base64
function _bytesToBase64(bytes) {
  const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('')
  return btoa(binString)
}

function _base64ToBytes(base64) {
  const binString = atob(base64)
  return Uint8Array.from(binString, (m) => m.codePointAt(0))
}

function uniCodeStrToBase64(uniStr) {
  return _bytesToBase64(new TextEncoder().encode(uniStr))
}

function getBestBrowserLocale() {
  const langs = navigator.languages
  let ret = null
  langs.forEach((it) => {
    if (it && it.length === 5 && ret === null) {
      ret = it
    }
  })
  return ret
}

function getTodayYyyyMmDd() {
  return new Date().toISOString().slice(0, 10).replace(/-/g, '')
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
// (i.e. full, long, medium, short)
function isoDateToListStr(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const userLang = navigator.language || navigator.userLanguage
  const theTimeInUtc = new Date(isoDateStr)
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const theTimeLocal = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  const nowIso = new Date().toISOString()
  const now = new Date(nowIso)
  let isToday = false
  if (
    theTimeLocal.getFullYear() === now.getFullYear() &&
    theTimeLocal.getMonth() === now.getMonth() &&
    theTimeLocal.getDate() === now.getDate()
  ) {
    isToday = true
  }

  const options = isToday
    ? {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }
    : {
      year: '2-digit',
      month: 'short',
      day: 'numeric'
    }
  return new Intl.DateTimeFormat(userLang, options).format(theTimeLocal)
}

function cntToKiloMili(num) {
  let ret = ""
  if (num>=1000) {
    ret = (num / 1000).toFixed(1) + "K"
  } else if (num>=1000000) {
    ret = (num / 1000000).toFixed(1) + "M"
  } else if (num>=1000000000) {
    ret = (num / 1000000000).toFixed(1) + "B"
  } else {
    ret = num
  }
  return ret
}

function isoDateToShortDate(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const userLang = navigator.language || navigator.userLanguage
  const theTimeInUtc = new Date(isoDateStr)
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const localTime = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  const options = {
    year: '2-digit',
    month: 'short',
    day: 'numeric'
  }
  return new Intl.DateTimeFormat(userLang, options).format(localTime)
}

function isoDateToShort(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const userLang = navigator.language || navigator.userLanguage
  const theTimeInUtc = new Date(isoDateStr)
  const theTimeInHour = Math.ceil(theTimeInUtc.getTime() / 3600 / 1000)
  const nowInHour = Math.ceil(new Date() / 3600 / 1000)
  const hourDiff = theTimeInHour - nowInHour
  const isToday = hourDiff < 24
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const localTime = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  const options = isToday
    ? {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
    : {
      year: '2-digit',
      month: 'short',
      day: 'numeric'
    }
  // return new Intl.DateTimeFormat(userLang, options).format(localDateTime)
  return new Intl.DateTimeFormat(userLang, options).format(localTime)
}


function listDateFrmtHtml(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const userLang = navigator.language || navigator.userLanguage
  const theTimeInUtc = new Date(isoDateStr)
  const theTimeInHour = Math.ceil(theTimeInUtc.getTime() / 3600 / 1000)
  const nowInHour = Math.ceil(new Date() / 3600 / 1000)
  const hourDiff = nowInHour - theTimeInHour
  const isToday = hourDiff < 24
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const localTime = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  const options = isToday
    ? {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
    : {
      month: 'short',
      day: 'numeric'
    }
  // return new Intl.DateTimeFormat(userLang, options).format(localDateTime)
  return isToday ?
    '<span class=\'text-primary fw-medium fs-time\'>' + localTime.toLocaleTimeString(userLang, options) + '</span>'
    : localTime.toLocaleDateString(userLang, options)
}

function isoDateToOnlyYear4(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const theTimeInUtc = new Date(isoDateStr)
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const localTime = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  return localTime.getFullYear()
}

function isoDateToYear4NoTime(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const userLang = navigator.language || navigator.userLanguage
  const theTimeInUtc = new Date(isoDateStr)
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const localTime = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  return new Intl.DateTimeFormat(userLang, options).format(localTime)
}

function isoDateToShortYear4(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const userLang = navigator.language || navigator.userLanguage
  const theTimeInUtc = new Date(isoDateStr)
  const theTimeInHour = Math.ceil(theTimeInUtc.getTime() / 3600 / 1000)
  const nowInHour = Math.ceil(new Date() / 3600 / 1000)
  const hourDiff = nowInHour - theTimeInHour
  const isToday = hourDiff < 24
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const localTime = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  const options = isToday
    ? {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
    : {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  return new Intl.DateTimeFormat(userLang, options).format(localTime)
  // return isToday?localDateTime.toLocaleTimeString(userLang, options):localDateTime.toLocaleDateString(userLang, options)
}

function isoDateToShortNoYear(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const userLang = navigator.language || navigator.userLanguage
  const theTimeInUtc = new Date(isoDateStr)
  const theTimeInHour = Math.ceil(theTimeInUtc.getTime() / 3600 / 1000)
  const nowInHour = Math.ceil(new Date() / 3600 / 1000)
  const hourDiff = nowInHour - theTimeInHour
  const isToday = hourDiff < 24
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const localTime = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  const options = isToday
    ? {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
    : {
      month: 'short',
      day: 'numeric'
    }
  return new Intl.DateTimeFormat(userLang, options).format(localTime)
}

function trnxStatCodeToNm(trnxCode) {
  switch (trnxCode) {
    case PaotTrnsStat.TRNX_OKAY:
      return '<i class="fa-solid fa-check-double text-primary fw-bold"></i>'
    case PaotTrnsStat.RQST_APRV:
      return '<i class="fa-duotone fa-stamp"></i>'
    case PaotTrnsStat.TRNX_PEND:
      return '<i class="fa-duotone fa-spinner text-primary fw-bold"></i>'
    case PaotTrnsStat.TRNX_FALD:
      return '<i class="fa-solid fa-text-slash text-warning fw-bold"></i>'
    case PaotTrnsStat.TRNX_PRTL_FALD:
      return '<i class="fa-solid fa-text-slash text-warning fw-bold"></i>'
    case PaotTrnsStat.TRNX_CNCL:
      return '<i class="fa-solid fa-text-slash text-warning fw-bold"></i>'
    case PaotTrnsStat.NO_WLETFUND:
      return '<i class="fa-solid fa-text-slash text-warning fw-bold"></i>'
    default:
      return ''
  }
}

String.prototype.format = function() {
  let formatted = this
  for (var arg in arguments) {
    formatted = formatted.replace('{' + arg + '}', arguments[arg])
  }
  return formatted
}

function format(str, arg) {
  let ret = str
  for (let i = 0; i < arg.length; i++) {
    ret = str.replace('{' + i + '}', arg[i])
  }
  return ret
}

function copySessToLocl() {
  let tf = sessionStorage.getItem(BrwsStrgKey.FO_JWT) // frontend j.w.t token
  if (tf) {
    localStorage.setItem(BrwsStrgKey.FO_JWT, tf)
  }
}

async function copyLoclToSess() {

  const tf = localStorage.getItem(BrwsStrgKey.FO_JWT) // frontend j.w.t token
  const tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
  const yes = localStorage.getItem(BrwsStrgKey.STOP_TOKN_RQST)

  if (tf && tf.length > 9) {
    user_stor().setTokn(tf)
  } else if (tp && tp.length > 9 && yes && yes === '1') {
    await rfshToknSyncFull()
  }
}

function preventEnter(e) {
  e.preventDefault()
  e.stopPropagation()
  e.returnValue = false
  e.target.blur()
  return false
}

const userAgentToListStr = (userAgnt) => {
  return userAgnt.slice(0, 12)
}

// https://github.com/danharper/hmac-examples
// https://stackoverflow.com/questions/49081874/i-have-to-hash-a-text-with-hmac-sha256-in-javascript
async function hashHmacSha256(message, secretKey) {
  // Convert the message and secretKey to Uint8Array
  const encoder = new TextEncoder()
  const messageUint8Array = encoder.encode(message)
  const keyUint8Array = encoder.encode(secretKey)
  // Import the secretKey as a CryptoKey
  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    keyUint8Array,
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  )
  // Sign the message with HMAC and the CryptoKey
  const signature = await window.crypto.subtle.sign('HMAC', cryptoKey, messageUint8Array)
  // Convert the signature ArrayBuffer to a hex string
  const hashArray = Array.from(new Uint8Array(signature))
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

const crcyFrmt = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

function getAuthPrvdByNameChar(pvdrNameChar) {
  let provider
  switch (pvdrNameChar) {
    case AuthPvdr.GOOGLE:
      provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account'
      })
      return provider
    case AuthPvdr.FACEBOOK:
      provider = new FacebookAuthProvider()
      provider.addScope('public_profile')
      provider.addScope('email')
      return provider
    case AuthPvdr.MICROSOFT:
      provider = new OAuthProvider('microsoft.com')
      provider.setCustomParameters({
        prompt: 'select_account',
        domain_hint: 'bitflow.ai'
      })
      return provider
  }
  return null
}

const usdCrcyFrmt = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export {
  hashHmacSha256,
  crcyFrmt,
  getAuthPrvdByNameChar,
  trnxStatCodeToNm,
  isoDateToListStr,
  getBestBrowserLocale,
  copySessToLocl,
  isoDateToShort,
  isoDateToShortNoYear,
  format,
  regexEmail,
  regexPasswordUpperLower,
  pxNum2Str,
  pxNum2StrLong,
  preventEnter,
  byteNum2StrLong,
  uniCodeStrToBase64,
  isoDateToShortYear4,
  userAgentToListStr,
  copyLoclToSess,
  byteNum2StrShrt,
  listDateFrmtHtml,
  isoDateToShortDate,
  usdCrcyFrmt,
  getTodayYyyyMmDd,
  pxNum2StrMidl,
  cntToKiloMili,
  isoDateToOnlyYear4,
  isoDateToYear4NoTime
}
